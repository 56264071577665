import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalConfirmDeleteComponent } from '../../modal/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'btn-delete-cart-item',
  templateUrl: './btn-delete-cart-item.component.html',
  styleUrls: ['./btn-delete-cart-item.component.scss']
})
export class BtnDeleteCartItemComponent implements OnInit {

  @Input("id") id; 
  @Input("nome") nome;
  @Input("index") index = null;
  public modalDel;   
  @ViewChild("modal") modal:ElementRef;
  public loader = false;
  public subscribeMessage = null;
  @Input("template") template = null;

  constructor(
    private CartService: CartService,
    private app: AppService,
    private api: ApiService,
    public _Modal: MatDialog
  ){

  }
  /**
   * 
   * Update Cart
   * 
   */
  updateCart(){

    this.CartService.deleteItemInCartEmit.emit(this.id)
    this.app.toTop();
    
  }
  /**
   * 
   * Deleta o serviço
   * 
   * 
   */
  deletar(){  

    try{

      this.loader = true;
      this.subscribeMessage = null;

      this.api.cart().delete(this.id).subscribe(response=>{

        this.loader = false;

        if(response.status == 1){
          
          this.updateCart();
        
        }else{

          this.subscribeMessage = "Houve um erro ao deletar o serviço. Tente novamente.";

        }

      }, (response) => {

        this.loader = false;
        this.subscribeMessage = "Houve um erro ao deletar o serviço. Tente novamente.";

      });
      

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Modal Delete
   * 
   */
  modalDelete(){

    let self = this;

    this.modalDel = this._Modal.open(ModalConfirmDeleteComponent,{
      width: "400px", 
      data: {
        nome: this.nome, 
        id: this.id,
        updateCart:()=>{
          this.modalDel.close();
          return self.updateCart()
        }
      }
    });

  }
  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){

  }

}

