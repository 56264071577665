import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {

  public nome : String
  public id : any
  public loader : boolean = false
  public widthInfo = "300px"
  @Output("updateCartItem") updateCartEmit = new EventEmitter();

  constructor( 
    public dialog: MatDialogRef<ModalConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private app: AppService
  ){ 
    this.nome = this.data.nome
    this.id   = this.data.id
  }
  /**
   * 
   * Delete
   * 
   **/
  delete(){
    
    try{
      
      this.loader = true; 
      
      this.api.cart().delete(this.id).subscribe(response=>{

        this.loader = false

        switch(response.status){
          case 1:
            this.data.updateCart(); 
          break;
          default:

            this.app.info("<h5>Houve um erro</h5><div>"+response.data+"</div>","danger");
    
          break;
        }

      },(err)=>{
        
        this.loader = false
        this.app.info("<h5>Houve um erro</h5><div>"+err._body+"</div>","danger");
       

      })

    }catch(e){

      this.loader = false
      this.app.info("<h5>Houve um erro</h5><div>"+e.message+"</div>","danger");
    
      
    }
  
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(){}

}
