<div class="dm-modal">
      <div class="dm-modal-title">
            <div class="close" (click)="dialog.close()">
                <i class="far fa-times-circle"></i> 
            </div>    
        </div> 
      <div class="modal-body">
            <form class="form form-custom" (submit)="send()">  
                  <div class="form-group">
                        <label>Nome:</label>  
                        <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                  </div> 
                  <div class="form-group">
                        <label>Telefone:</label> 
                        <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" mask="(99) 99999-9999" placeholder="(**) *****-****" />
                  </div>
                  <div class="form-group">
                        <label>E-mail (Opcional):</label>
                        <input type="text" [(ngModel)]="dataItem.email" name="email" />
                  </div>
                  <div class="form-group">
                        <label>Descrição:</label>
                        <textarea [(ngModel)]="dataItem.descricao" name="email" rows="5"></textarea>
                  </div>
                  <div class="form-group form-button">
                        <button type="submit" class="btn btn-one btn-icon"> 
                              <div class="icon">
                                    <i class="fa-solid fa-paper-plane"></i>
                              </div>
                              <span>Enviar</span>
                        </button>
                  </div>
            </form>
      </div> 
</div>
<loader *ngIf="loader"></loader>
    