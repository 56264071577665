import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartSidebarComponent } from 'src/app/components/carrinho/dm-cart-sidebar/dm-cart-sidebar.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import { PartialModule } from '../../partial/partial.module';
import { CartItemTwoModule } from '../cart-item-two/cart-item-two.module';

@NgModule({
  declarations: [
    DmCartSidebarComponent
  ],
  exports: [
    DmCartSidebarComponent
  ],
  imports: [
    CommonModule,
    CartItemTwoModule,
    LoaderModule,
    PartialModule    
  ]
})  
export class CartSidebarModule{}
