import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @Input("data") data:any           = null; 
  @Input("template") template:any   = null; 
  @Input("classCustom") classCustom = "";
  public dataItem = {
    termo: ""
  }

  constructor(
    public router: Router
  ){}

  /**
   * 
   * Send
   * 
   */
  send(){ 

    if(this.dataItem.termo != "" && this.dataItem.termo != null && this.dataItem.termo != "null"){
      this.router.navigate(["/produtos"],{queryParams:this.dataItem}); 
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
