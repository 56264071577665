import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegerDirective } from 'src/app/directives/integer.directive';
import { MaskDirective } from 'src/app/directives/mask.directive';
import { PhoneMaskDirective } from 'src/app/directives/phone-mask.directive';
import { ServicoObservacoesFormatDirective } from 'src/app/directives/servico-observacoes-format.directive';
import { TypeWriteDirective } from 'src/app/directives/type-write.directive';
import { UppercaseDirective } from 'src/app/directives/uppercase.directive';
import { MaskMoneyDirective } from 'src/app/directives/mask-money.directive';

@NgModule({
  declarations: [
    IntegerDirective,
    MaskDirective,
    PhoneMaskDirective,
    ServicoObservacoesFormatDirective,
    TypeWriteDirective,
    UppercaseDirective,
    MaskMoneyDirective
  ],
  exports: [
    IntegerDirective,
    MaskDirective,
    PhoneMaskDirective,
    ServicoObservacoesFormatDirective,
    TypeWriteDirective,
    UppercaseDirective,
    MaskMoneyDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
