import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-produto-destaque-semana',
  templateUrl: './produto-destaque-semana.component.html',
  styleUrls: ['./produto-destaque-semana.component.scss']
})
export class ProdutoDestaqueSemanaComponent implements OnInit {

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  @ViewChild("slider") slider:ElementRef;
  public width = 0;
  
  constructor(
    private router: Router
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(url){

    this.router.navigateByUrl(url); 

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    if(typeof(this.slider) != "undefined"){

      $(this.slider.nativeElement).slick({ 
        infinite: true,
        slidesToShow: 4,
        dots: false, 
        arrows: false,
        centerMode: false,
        centerPadding: '0',
        lazyLoad: 'ondemand',
        responsive: [{
            breakpoint: 1224,
            settings: {
              slidesToShow: 4,  
            }
        },{
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,

            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              centerPadding: '0',
            }
        }
      ]
      
      }); 
      $(this.slider.nativeElement).slick('refresh'); 
    
    }
    
  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Set width
   * 
   */
  setWidth(value:any){

    this.width = value.target.innerWidth;
    setTimeout(() => {
      this.initSlider();
    },100);
    
  }
  /** 
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.width = window.innerWidth;
  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }

}
