<div class="module module-welcome">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <div class="box-info wow slideInLeft">
                      <h2>
                          <img src="images/lapidace/marca-simbolo.svg" alt="Lapidace" width="40px" />
                          {{data.params.titulo_one}}
                      </h2>
                      <article [innerHtml]="data.params.texto_one"></article>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="box-info wow slideInRight">
                        <h2>{{data.params.titulo_two}}</h2>
                        <article [innerHtml]="data.params.texto_two"></article>
                      <form class="form" (submit)="send()">
                          <div class="form-input">
                              <input type="text" [(ngModel)]="dataItem.termo" placeholder="O que você procura? Digite aqui..." name="termo" autocomplete="termo" />
                              <button>  
                                <i class="material-icons">search</i>
                              </button>
                          </div>      
                      </form>
                  </div>
              </div>
          </div>    
      </div>    
  </div>    
  