import { Injectable } from '@angular/core';
import { DataService } from './data.service';

declare var fbq:any; 
declare var document:any; 

@Injectable({
  providedIn: 'root'
})
export class FbService {
   
  public pixel_codigo     = null;
  public pixel_share_eat  = null;

  constructor(
    private db: DataService
  ) { }

  /**
   * 
   * Init
   * 
   */
  init(){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    let config = this.db.getConfiguracao();

    this.pixel_codigo    = config.pixel_codigo;
    this.pixel_share_eat = config.pixel_share_eat;

  }
  /**
   * 
   * Init
   * 
   */
  initShareEat(){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    let config = this.db.getConfiguracao();

  }
  /**
   * 
   * Add to cart
   * 
   */
  addCart(produto){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    
    fbq('track','AddToCart',{
      content_name: produto.nome, 
      content_category: produto.categoria,
      content_ids: [produto.id], 
      content_type: 'product',
      value: produto.valor,
      currency: 'BRL' 
    }); 

  }
  /**
   * 
   * Add to cart
   * 
   */
  addCartSingle(produto,p=1){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    
    let pixel = p == 1 ? this.pixel_codigo : this.pixel_share_eat;

    fbq('trackSingle',pixel,'AddToCart',{
      content_name: produto.nome, 
      content_category: produto.categoria,
      value: produto.valor,
      currency: 'BRL' 
    });  

  }
  /**
   * 
   * Page View
   * 
   */
  pageView(){

    if(typeof(fbq) == "undefined"){
      return false;
    }

    fbq('track','PageView'); 
 
  }
  /**
   * 
   * Page View
   * 
   */
  pageViewSingle(p=1){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    let pixel = p == 1 ? this.pixel_codigo : this.pixel_share_eat;
    
    fbq('trackSingle',pixel,'PageView');  
    
 
  }
  /**
   * 
   * Page View
   * 
   */
  contact(){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    fbq('track', 'Contact');
 
 
  }
  /**
   * 
   * View content
   * 
   * @param produto 
   * 
   */
  viewContent(produto){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    fbq('track', 'viewContent',{
      content_category: produto.categoria,
      content_name: produto.nome, 
      value: produto.valor,
      currency: "BRL"
    });

  }
  /**
   * 
   * View content
   * 
   * @param produto 
   * 
   */
  viewContentSingle(produto,p=1){

    if(typeof(fbq) == "undefined"){
      return false;
    }
    let pixel = p == 1 ? this.pixel_codigo : this.pixel_share_eat;
    
    fbq('trackSingle',pixel.toString(),'viewContent',{
      content_category: produto.categoria,
      content_name: produto.nome, 
      value: produto.valor,
      currency: "BRL",
    });

  }
  /***
   * 
   * Envia o pagamento
   * 
   */
  purchase(data){

    if(typeof(fbq) == "undefined"){
      return false;
    }

    fbq('track', 'Purchase',{
      value: data.total, 
      currency: 'BRL'
    });
    
  }
  /**
   * 
   * Init Checkout
   * 
   */
  initCheckout(data){

    if(typeof(fbq) == "undefined"){
      return false;
    }

    fbq('track', 'InitiateCheckout',{
      value: data.total,
      //content_ids: data.content_ids,
      num_items: data.num_items,
      currency: 'BRL',
      //content_type: 
    });
    
  }
  /**
   * 
   * Init Checkout
   * 
   */
  initCheckoutSingle(data,p=1){

    if(typeof(fbq) == "undefined"){
      return false;
    }

    let pixel = p == 1 ? this.pixel_codigo : this.pixel_share_eat;

    fbq('trackSingle',pixel,'InitiateCheckout',{
      value: data.total,
      //content_ids: data.content_ids,
      num_items: data.num_items,
      currency: 'BRL',
      //content_type: 
    });
    
  }
  /**
   * 
   * Lead
   * 
   */
  lead(percent=10){
    
    if(typeof(fbq) == "undefined"){
      return false;
    }

    this.executeWhenReachedPagePercentage(percent,() => {
      fbq('track', 'Lead');
    });

  }
  /**
   * 
   * Lead single
   * 
   */
  leadSingle(p=1){

    if(typeof(fbq) == "undefined"){
      return false;
    }

    let pixel = p == 1 ? this.pixel_codigo : this.pixel_share_eat;
      
    //this.executeWhenReachedPagePercentage(percent,pixel,() => {
      fbq('trackSingle',pixel,'Lead'); 
    //});
  
  }
  executeWhenReachedPagePercentage = (percentage, callback) => {
    
    if (typeof percentage !== 'number') {
      console.error(
        'First parameter must be a number, got',
        typeof percentage,
        'instead',
      );
    }
  
    if (typeof callback !== 'function') {
      console.error(
        'Second parameter must be a function, got',
        typeof callback,
        'instead',
      );
    }
  
    function getDocumentLength() {
      var D = document;
      return Math.max(
          D.body.scrollHeight, D.documentElement.scrollHeight,
          D.body.offsetHeight, D.documentElement.offsetHeight,
          D.body.clientHeight, D.documentElement.clientHeight
      )
    }
  
    function getWindowLength() {
      return window.innerHeight || 
        (document.documentElement || document.body).clientHeight;
    }
  
    function getScrollableLength() {
      if (getDocumentLength() > getWindowLength()) {
        return getDocumentLength() - getWindowLength();
      } else {
        return 0;
      }
    }
  
    var scrollableLength = getScrollableLength();
  
    window.addEventListener("resize", function(){
      scrollableLength = getScrollableLength();
    }, false)
  
    function getCurrentScrolledLengthPosition() {
     return window.pageYOffset || 
       (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
  
    function getPercentageScrolled() {
      if (scrollableLength == 0) {
        return 100;
      } else {
        return getCurrentScrolledLengthPosition() / scrollableLength * 100;
      }
    }
  
    var executeCallback = (function() {
      var wasExecuted = false;
      return function() {
        if (!wasExecuted && getPercentageScrolled() > percentage) {
          wasExecuted = true;
          callback();
        }
      };
    })();
  
    if (getDocumentLength() == 0 ||
      (getWindowLength()/getDocumentLength() * 100 >= percentage)) {
      callback();
    } else {
      window.addEventListener('scroll', executeCallback, false);
    }

  }
  /**
   * 
   * Track Single
   * 
   */
  trackSingle(){

    fbq('trackSingle','<PIXEL_A>','Purchase',{ 
      value: 4 , 
      currency: 'BRL'
    }); 

  }
  /**
   * 
   * Track Single
   * 
   */
  trackSingleCustom(){
    
    fbq('trackSingleCustom','FB_PIXEL_ID', 'CustomEvent',{});  
  
  }


}
