<div class="cart-item">
    <div class="box"> 
      <div (click)="_route()" class="capa" *ngIf="item.imagem == null">
          <img width="100%" lazyLoad="/images/produto/sem-foto/default.jpg" [alt]="item.nome" class="img-fluid" />
        </div>  
        <div (click)="_route()" class="capa" *ngIf="item.imagem != null">
          <img width="100%" defaultImage="/images/produto/sem-foto/default.jpg" [lazyLoad]="item.imagem" [alt]="item.nome" class="img-fluid" />
        </div>   
    </div>
    <div class="box">  
      <h4>{{item.nome}}</h4>
      <btn-delete-cart-item template="two" [id]="item.id" [nome]="item.nome"></btn-delete-cart-item>
    </div>
    <div class="box"> 

    </div>
</div>