import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class CartService {

  public emitirDeleteItem          = new EventEmitter();
  public emitirUpdateTotalCart     = new EventEmitter(); 
  public emitirUpdateDesconto      = new EventEmitter();
  public emitirUpdateCupom         = new EventEmitter();
  public emitirAddItem             = new EventEmitter();
  public updateCartDataByPagamento = new EventEmitter();
  public deleteItemInCartEmit      = new EventEmitter();     
  public openCart = new EventEmitter();


  constructor(){} 
  
  /**
   * 
   * @param data 
   * 
   */
  formatOrderToWhatsApp(data){

    let text = "";

    text += "Pedido: "+data.carrinho.id;
    text += "\n\n";
    text += "Nome: "+data.carrinho.nome;
    text += "\n";
    text += "Telefone: "+data.carrinho.telefone;
    text += "\n";
    text += "E-mail: "+data.carrinho.email;
    text += "\n";
    text += "Data Pedido: "+data.carrinho.data_cart;
    text += "\n"; 
    text += "-----------------------------------------";
    text += "\n\n";
    text += "Produtos:"
    text += "\n\n";

    for(let index = 0; index < data.produtos.length;index++){
      
      text += "ID: "+data.produtos[index].id;  
      text += "\n";
      text += "Nome: "+data.produtos[index].nome;
      text += "\n";
      text += "Quantidade: "+data.produtos[index].quantidade; 
      text += "\n\n";  
      
    }
    text += "-----------------------------------------";
    text += "\n\n";  

    return window.encodeURIComponent(text); 

  }

  
}
