<div class="module modulo-depoimentos">
      <div class="module-title">
        <div class="container">
          <h3>{{data.titulo}}</h3>
          <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
        </div>
      </div>  
      <div class="module-content">
        <div class="container">
          <div class="ratings slider-items" #slider>  
            <div class="slider-item" *ngFor="let d of data.depoimentos">
                <depoimento-item [data]="d"></depoimento-item>
            </div>      
          </div>
          <div class="navigate-content">
            <div class="slide-navigate-item prev" (click)="prev()">
              <i class="fa-solid fa-angle-left"></i>
            </div>
            <div class="slide-navigate-item next" (click)="next()">
              <i class="fa-solid fa-angle-right"></i>
            </div> 
          </div>  
        </div>  
      </div>
</div>
    