import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnCartComponent } from 'src/app/components/btns/btn-cart/btn-cart.component';

@NgModule({
  declarations: [
    BtnCartComponent    
  ],
  exports: [
    BtnCartComponent
  ],
  imports: [
    CommonModule  
  ]
})
export class BtnCartModule{}
