<div class="module module-products" (window:resize)="setWidth($event)" *ngIf="data.produtos.length > 0">
  <div class="module-title" *ngIf="data.mostrar_titulo">
      <div class="container"> 
          <h2>{{data.titulo}}</h2> 
          <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
      </div>    
  </div>
  <div class="module-content">
     <div class="container">
        <produto-item-slider-two [produtos]="data.produtos"></produto-item-slider-two>
      </div>
  </div>
  <div class="see-all center" (click)="toRoute()">
      <div class="content">
          <a class="btn-custom-1" href="javascript::void();" title="Produtos">
            <span>Ver todos</span>
            <div class="icon">
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </a> 
      </div>
  </div>  
</div>    
