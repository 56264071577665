import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnAddCartComponent } from 'src/app/components/carrinho/btn/btn-add-cart/btn-add-cart.component';
import { LoaderModule } from '../../loader/loader/loader.module';
import { DmCardEstoqueModalComponent } from 'src/app/components/carrinho/modal/dm-card-estoque-modal/dm-card-estoque-modal.component';
import { BtnCartSemEstoqueComponent } from 'src/app/components/carrinho/btn/btn-cart-sem-estoque/btn-cart-sem-estoque.component';
import { DmCartContatoComponent } from 'src/app/components/carrinho/modal/dm-cart-contato/dm-cart-contato.component';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProdutoIntencaoModalComponent } from 'src/app/components/produto/produto-intencao-modal/produto-intencao-modal.component';

@NgModule({
    declarations: [
        BtnAddCartComponent,
        DmCardEstoqueModalComponent,
        BtnCartSemEstoqueComponent,
        DmCartContatoComponent,
        ProdutoIntencaoModalComponent
    ],
    exports: [
        BtnAddCartComponent,  
        DmCardEstoqueModalComponent,
        BtnCartSemEstoqueComponent,
        DmCartContatoComponent
    ],
    entryComponents: [
        DmCartContatoComponent,
        ProdutoIntencaoModalComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        DirectivesModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class BtnCartAddModule{}
