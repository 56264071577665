<div class="rating-item">
      <div class="content">
          <h4>{{data.nome}}</h4>
          <div class="stars">
              <div class="item">
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id < 1" class="border"></i>
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id >= 1"></i>
              </div> 
              <div class="item">
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id < 2" class="border"></i>
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id >= 2"></i>
              </div> 
              <div class="item">
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id < 3" class="border"></i>
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id >= 3"></i>
              </div> 
              <div class="item">
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id < 4" class="border"></i>
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id >= 4"></i>
              </div> 
              <div class="item">
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id < 5" class="border"></i>
                  <i class="fa-solid fa-star" *ngIf="data.depoimento_avaliacao_id >= 5"></i>
              </div>    
          </div> 
          <article class="description" [innerHtml]="data.texto"></article>
      </div>    
  </div>    