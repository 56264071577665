import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service'; 
import { CartService } from 'src/app/services/cart.service';
import { DataService } from 'src/app/services/data.service';
import { FbService } from 'src/app/services/fb.service';
import { DmCardEstoqueModalComponent } from '../../modal/dm-card-estoque-modal/dm-card-estoque-modal.component';
import { DmCartAddSuccessModalComponent } from '../../modal/dm-cart-add-success-modal/dm-cart-add-success-modal.component';
import { DmCartContatoComponent } from '../../modal/dm-cart-contato/dm-cart-contato.component';

@Component({
  selector: 'btn-add-cart', 
  templateUrl: './btn-add-cart.component.html',
  styleUrls: ['./btn-add-cart.component.scss']
})
export class BtnAddCartComponent implements OnInit {

  @Input("produto") produto = null;
  public loader             = false;
  public dataItem:any       = {};
  public configuracao:any   = null;
  @Input("classCustom") classCustom:any = "";

  constructor(
    private app: AppService,
    private api: ApiService,
    private cartService: CartService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialog: MatDialog,
    private dataSertice: DataService,
    private fb: FbService
  ){}
  /**
   * 
   * Set contato cart
   * 
   */
  setContatoCart(){

    let contato:any = this.dataSertice.getContatoCart(); 

    if(contato != null){
      
      this.dataItem.telefone = contato.telefone; 
      this.dataItem.email    = contato.email;

    }

  }
  /**
   * 
   * 
   * 
   */
  add(verificarPre=true){ 

    try{

      if(verificarPre){
        this.setContatoCart()
      }

      if((this.configuracao.solicitar_telefone_pre_venda && this.dataItem.telefone == null) && verificarPre){
        
        this.dialog.open(
          DmCartContatoComponent,{
            width: "450px",
            data: {
              dataItem: this.dataItem,
              self: this
            }
          } 
        );
        return false;
      
      }
      this.loader = true;    

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;   

          this.api.loja().carrinho().store(this.dataItem).subscribe((response) => {

            this.loader = false;

            if(response.status == 1){

              this.fb.addCart({
                id: this.produto.id,
                categoria: this.produto.categoria_titulo,
                nome: this.produto.nome,
                valor: this.produto.valor
              });
              this.cartService.emitirUpdateTotalCart.emit(response.data.total_cart);
              this.cartService.openCart.emit(true);

              /*this.dialog.open(
                DmCartAddSuccessModalComponent,{
                data: {
                  nome: this.produto.nome
                } 
              });*/   
            
            }else if(response.status == 2){

              this.dialog.open(
                DmCardEstoqueModalComponent,{
                  width: "450px",
                  data: {
                    nome: this.produto.nome,
                    data: response.data
                  } 
                }
              );  

            }else if(response.status == 3){

              this.cartService.openCart.emit(false);

            }else{

              let error   = this.api.formatError(response);
              this.app.info(error.message);

            }

          },(response) => {

            this.loader = false;
            let error   = this.api.formatError(response);

            this.app.info(error.message);

          });

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);

        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      this.app.info(e.message);

    }
    
  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: this.produto.nome,
      produto_id: this.produto.id,
      produto_categoria_id: this.produto.produto_categoria_id,
      valor: this.produto.promocao ? this.produto.promocao_valor : this.produto.valor,
      quantidade: 1,
      token: null,
      email: null,
      telefone: null
    }

  }
  /**
   * 
   * Set configuracao
   * 
   */
  setConfiguracao(){

    this.configuracao = this.dataSertice.getConfiguracao(); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
    this.setConfiguracao();
  }

}
