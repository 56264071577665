<div class="module module-categories">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2>{{data.titulo}}</h2>
              <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
          </div>
      </div>    
      <div class="module-content">
          <div class="container">
                <produto-item-slider-two type="2" [produtos]="data.categorias"></produto-item-slider-two>
          </div>
      </div>
  </div>
  