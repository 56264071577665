import { Injectable } from '@angular/core';
import { AppSettings } from '../class/AppSettings';

@Injectable()
export class StorageService {

  constructor(){}

   /**
   * 
   * Set Data File
   * 
   */
  setDataCarrinho(data){

    sessionStorage.setItem("data_carrinho",JSON.stringify(data));
    
  }
  /**
   * 
   * Get Data File
   * 
   */
   getDataCarrinho(){  

    let data = sessionStorage.getItem("data_carrinho");    

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
   /**
   * 
   * Set Data File
   * 
   */
  setRastreio(data,codigo){

    sessionStorage.setItem("rastreio_"+codigo,JSON.stringify(data));
  
  }
  /**
   * 
   * Get Data File
   * 
   */
   getRastreio(codigo){

    let data = sessionStorage.getItem("rastreio_"+codigo);

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * Set Data File
   * 
   */
   setSearchProdutos(data){

    sessionStorage.setItem("dataSearchProductos",JSON.stringify(data));

  }
  /**
   * 
   * Get Data File
   * 
   */
   getSearchProdutos(){

    let data = sessionStorage.getItem("dataSearchProductos");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * Set Data File
   * 
   */
  setDataFile(dataParams,dataResponse){

    let prefixo = AppSettings.PREFIXO;  
    let params = {
      dataItem: dataParams,
      response: dataResponse 
    }

    sessionStorage.setItem(prefixo+"dataFile",JSON.stringify(params));

  }
  /**
   * 
   * Get Data File
   * 
   */
  getDataFile(){

    let prefixo = AppSettings.PREFIXO;
    let data = sessionStorage.getItem(prefixo+"dataFile");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * Set Data File
   * 
   */
  setUltimoServico(data){

    let prefixo = AppSettings.PREFIXO;

    sessionStorage.setItem(prefixo+"ultimoServico",JSON.stringify(data));

  }
  /**
   * 
   * Get Data File
   * 
   */
  getUltimolServico(){

    let prefixo = AppSettings.PREFIXO;
    let data = sessionStorage.getItem(prefixo+"ultimoServico");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * 
   */
  remoteUltimolServico(){

    let prefixo = AppSettings.PREFIXO;
    
    sessionStorage.removeItem(prefixo+"ultimoServico");

  }
  /**
   * 
   * Set Usuário
   * 
   */
  setUsuario(data){

    sessionStorage.setItem("usuario",JSON.stringify(data));

  }
  /**
   * 
   * Get Data File
   * 
   */
  getUsuario(){

    let data = sessionStorage.getItem("usuario");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }

  }
  /**
   * 
   * Remove Usuário
   * 
   */
  removeUsuario(){

    sessionStorage.removeItem("usuario");

  }
  /**
   * 
   * Set Usuário
   * 
   */
  setPagamento(data){
    sessionStorage.setItem("data_pagamento",JSON.stringify(data));
  }
  /**
   * 
   * Get Data File
   * 
   */
  getPagamento(){

    let data = sessionStorage.getItem("data_pagamento");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }
    

  }
  

}
