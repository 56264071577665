import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartItemTwoComponent } from 'src/app/components/carrinho/dm-cart-item-two/dm-cart-item-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { PartialModule } from '../../partial/partial.module';
import { LoaderModule } from '../../loader/loader/loader.module';
import { BtnDeleteCartItemModule } from '../btn-delete-cart-item/btn-delete-cart-item.module';

@NgModule({
  declarations: [
    DmCartItemTwoComponent
  ],
  exports: [
    DmCartItemTwoComponent 
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    PartialModule,
    LoaderModule,
    BtnDeleteCartItemModule
  ]
})
export class CartItemTwoModule{}
