<div class="dm-modal">   
      <div class="dm-modal-content">
            <div class="content">
                  Deseja retirar <strong>{{nome}}</strong> do carrinho?
            </div>
            <div class="footer"> 
                  <div class="center"> 
                        <button type="button" (click)="delete()" class="btn-one">
                              Sim, quero excluir
                        </button>
                        <button type="button" (click)="dialog.close()" class="btn-two">
                              Não
                        </button>
                  </div>  
            </div>
      </div>  
</div>  
<loader *ngIf="loader"></loader>