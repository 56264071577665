<section class="modulo-loader {{classCustom}}">
    <div class="loader-wrap">
      <div class="content">
        <figure class="animated infinite pulse">    
          <img src="/images/lapidace/marca-simbolo.svg" width="35px" height="35px" alt="Lapidace - Joias e Acessórios" />
        </figure>   
        <span class="message">{{message}}</span> 
      </div>  
    </div>
    <div class="overlay"></div> 
</section>
    