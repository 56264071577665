<div [ngSwitch]="template">
  <section *ngSwitchCase="null" class="default-footer">
    <div class="modulo-title">
      <h3>
        Contato
      </h3>
    </div>
    <div class="nav-menu">
      <ul>
          <li>
              <a href="tel:{{data.params.whatsapp}}" title="Telefone {{data.params.whatsapp}}">
                <div class="icon">
                  <i class="fa-solid fa-phone"></i>
                </div> 
                <span>{{data.params.whatsapp}}</span>
              </a>
          </li>
          <li>
              <a href="javascript::void();" title="WhatsApp {{data.params.whatsapp}}" (click)="openWhatsApp()">
                <div class="icon">  
                  <i class="fa-brands fa-whatsapp"></i>
                </div>
                <span>{{data.params.whatsapp}}</span>
              </a>
          </li>
          <li>
              <a [href]="data.params.grupo_whatsapp" title="WhatsApp {{phone}}" target="_blank">
                  <div class="icon">
                    <i class="fa-brands fa-whatsapp"></i>
                  </div>
                  <span>Entrar no Grupo Exclusivo</span>
              </a>
          </li>
      </ul>
  </div> 
  </section> 
  <div *ngSwitchCase="'page-contato'" class="page-contato">
    <a class="contact-item" href="mailto:{{data.params.email}}" title="E-mail: {{data.params.email}}">
      <span class="title">E-mail</span> 
      <strong class="value">{{data.params.email}}</strong>
    </a>
  </div>
  <div *ngSwitchCase="'checkout-pedido'" class="checkout-pedido">
    <a class="content-item" href="tel:{{data.params.celular}}" title="E-mail: {{data.params.celular}}">
      <span class="title">Se preferir, ligue pra nós:</span> 
      <strong class="value">
        <i class="material-icons">call</i>
        {{data.params.celular}}
      </strong>
    </a>
  </div> 
</div>  

