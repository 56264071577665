<div class="product-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
  <div class="capa" *ngIf="produto.foto != null">
    <a [href]="link" (click)="_click()">
      <img *ngIf="isLazy" defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]='produto.foto' [alt]="produto.foto_alt" width="263px" height="174px" />
      <img *ngIf="!isLazy" [src]='produto.foto' [alt]="produto.foto_alt" width="263px" height="174px" />
    </a>  
  </div>  
  <div class="capa" *ngIf="produto.foto == null">
    <a [href]="link" (click)="_click()" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
      <img itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" [alt]="produto.nome" width="263px" height="174px" />
    </a> 
  </div>
  <div class="content">
      <h4>{{produto.nome}}</h4>
      <div class='content-description'>
        <article *ngIf="produto.descricao_abreviada != null && produto.descricao_abreviada != ''" [innerHtml]="produto.descricao_abreviada | safeHtml"></article>
      </div>
      <div class="linha"></div>
      <div class="footer">
          <div class="box">
              <div class="tariff-promotion" *ngIf="produto.promocao">
                <span>De</span>
                <strong>{{produto.valor | currency: " R$ "}}</strong> 
                <span>por</span>
              </div> 
              <div class="tariff-promotion" *ngIf="!produto.promocao">
                <span>Por apenas:</span>
              </div> 
              <div class="tariff">   
                  <strong class="value" *ngIf="!produto.promocao">{{produto.valor | currency: " R$ "}}</strong>
                  <strong class="value" *ngIf="produto.promocao">{{produto.promocao_valor | currency: " R$ "}}</strong>
              </div> 
              <div class="installments" *ngIf="produto.mostrar_parcelas">
                  <span *ngIf="!produto.promocao">{{produto.parcelas}}x de {{(produto.valor/produto.parcelas) | currency: " R$ "}}</span>
                  <span *ngIf="produto.promocao">{{produto.parcelas}}x de {{(produto.promocao_valor/produto.parcelas) | currency: " R$ "}}</span>
              </div>
          </div>   
      </div> 
      <div class="footer-links">
        <div class="box" *ngIf="!produto.vender_offline">     
          <btn-cart-sem-estoque [produtoId]="produto.id" *ngIf="estoque.quantidade_atual <= 0"></btn-cart-sem-estoque>
          <btn-add-cart *ngIf="estoque.quantidade_atual > 0" [estoque]="estoque" [produto]="produto"></btn-add-cart> 
        </div> 
      </div>    
  </div>  
</div>

  