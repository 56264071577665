import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { MaxPipe } from 'src/app/pipes/max.pipe';
import { FormatDuracaoPipe } from 'src/app/pipes/format-duracao.pipe';
import { DateFormatTextPipe } from 'src/app/pipes/date-format-text.pipe';
import { TipoPassageiroDescPipe } from 'src/app/pipes/tipo-passageiro-desc.pipe';
import { GetTarifaMinimaListaPipe } from 'src/app/pipes/get-tarifa-minima-lista.pipe';
import { FormatValorManagerPipe } from 'src/app/pipes/format-valor-manager.pipe';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { FilterCustomPipe } from 'src/app/pipes/filter-custom.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    MaxPipe,
    DateFormatTextPipe,
    FormatDuracaoPipe,
    TipoPassageiroDescPipe,
    GetTarifaMinimaListaPipe,
    FormatValorManagerPipe,
    FilterPipe,
    FilterCustomPipe
  ],
  exports: [
    SafeHtmlPipe,
    MaxPipe,
    FormatDuracaoPipe,
    DateFormatTextPipe,
    TipoPassageiroDescPipe,
    GetTarifaMinimaListaPipe,
    FormatValorManagerPipe,
    FilterPipe,
    FilterCustomPipe 
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule{} 
