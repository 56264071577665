<div class="dm-modal">
    <div class="dm-modal-title">
      <!--<div class="close" (click)="_close()">
          <i class="far fa-times-circle"></i> 
      </div>-->    
    </div> 
    <div class="dm-modal-content">   
      <article class="description">
        <p>O produto "<strong>{{nome}}</strong>" não está disponível para a compra no momento.</p>
      </article>  
    </div>  
    <div class="dm-modal-footer">  
      <button type="button" (click)="_close()" class="btn-one">
        <i class="fa-solid fa-circle-check"></i>
        <span>OK</span>
      </button>  
    </div>
</div>    