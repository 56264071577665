import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-lista',
  templateUrl: './modulo-lista.component.html',
  styleUrls: ['./modulo-lista.component.scss']
})
export class ModuloListaComponent implements OnInit {

  @Input("data") data;
  public logo = null;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null; 

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {}

}
