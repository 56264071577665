import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { ModuloNewsletterModule } from './modulo-newsletter/modulo-newsletter.module';
import { ProdutoDestaqueOneComponent } from 'src/app/modulos/produto-destaque-one/produto-destaque-one.component';
import { ProdutoDestaqueTwoComponent } from 'src/app/modulos/produto-destaque-two/produto-destaque-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { ModuloFaleConoscoModule } from './modulo-fale-conosco/modulo-fale-conosco.module';
import { VideoComponent } from 'src/app/modulos/video/video.component';
import { WelcomeComponent } from 'src/app/modulos/welcome/welcome.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GrupoWhatsappComponent } from 'src/app/modulos/grupo-whatsapp/grupo-whatsapp.component';
import { DepoimentosComponent } from 'src/app/modulos/depoimentos/depoimentos.component';
import { DepoimentoItemModule } from '../depoimento/depoimento-item/depoimento-item.module';
import { ProdutoCategoriasComponent } from 'src/app/modulos/produto-categorias/produto-categorias.component';
import { ProdutoDestaqueSemanaComponent } from 'src/app/modulos/produto-destaque-semana/produto-destaque-semana.component';
import { ModuloListaComponent } from 'src/app/modulos/modulo-lista/modulo-lista.component';
import { ProdutoDestaquesComponent } from 'src/app/modulos/produto-destaques/produto-destaques.component';
import { ModuloBannerSliderModule } from './modulo-banner-slider/modulo-banner-slider.module';
import { ModuloAtendimentoPersonalizadoModule } from './modulo-atendimento-personalizado/modulo-atendimento-personalizado.module';

@NgModule({
  declarations: [
    Modulo,
    ProdutoDestaqueOneComponent,
    ProdutoDestaqueTwoComponent,
    EnderecoComponent,
    VideoComponent,
    WelcomeComponent, 
    GrupoWhatsappComponent,
    DepoimentosComponent,
    ProdutoCategoriasComponent,
    ProdutoDestaqueSemanaComponent,
    ModuloListaComponent,
    ProdutoDestaquesComponent  
  ],
  exports:[
    Modulo,
    EnderecoComponent,
    VideoComponent,
    WelcomeComponent,
    GrupoWhatsappComponent,
    DepoimentosComponent,
    ProdutoCategoriasComponent,
    ProdutoDestaqueSemanaComponent,
    ModuloListaComponent,
    ProdutoDestaquesComponent
  ],
  imports: [
    ModuloFaleConoscoModule,
    ModuloWhatsappModule,  
    ProdutoPartialModule,
    DepoimentoItemModule, 
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    ModuloNewsletterModule,
    CommonModule,
    PipeModule ,
    FormsModule,
    ReactiveFormsModule,
    ModuloBannerSliderModule,
    ModuloAtendimentoPersonalizadoModule
  ]
})
export class ModuloModule{}
