import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnDeleteCartItemComponent } from 'src/app/components/carrinho/btn/btn-delete-cart-item/btn-delete-cart-item.component';

@NgModule({
  declarations: [
    BtnDeleteCartItemComponent
  ],
  exports: [
    BtnDeleteCartItemComponent
  ],
  imports: [
    CommonModule  
  ]
})
export class BtnDeleteCartItemModule{} 
