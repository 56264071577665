import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'depoimento-item',
  templateUrl: './depoimento-item.component.html',
  styleUrls: ['./depoimento-item.component.scss']
})
export class DepoimentoItemComponent implements OnInit {

  @Input("data") data:any = null;

  constructor(){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}  

}
