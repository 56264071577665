import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'dm-cart-sidebar',
  templateUrl: './dm-cart-sidebar.component.html',
  styleUrls: ['./dm-cart-sidebar.component.scss']
})
export class DmCartSidebarComponent implements OnInit {

  public open  = false;
  public total = 0;
  public response:any = {
    data: "",
    status: 0
  }
  public produtos = [];  
  public loader   = false;

  constructor(
    private cart: CartService,
    private router: Router,
    private api: ApiService,
    private app: AppService,
    private storage: StorageService
  ){}

  /**
   * 
   * To Cart
   * 
   */
  _toCart(){

    this.router.navigateByUrl("/carrinho"); 
    this.open = false;

  }
  /**
   * 
   * Close
   * 
   */
  _close(){

    this.open = this.open == true ? false : true;

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.cart.openCart.subscribe((status) => {

      this.open = true;

      if(status){
        this.getData()
      }

    });
    this.cart.deleteItemInCartEmit.subscribe(id => {
      this.deleteItem(id);
    });

  }
  /**
   * 
   * Deleta um Item no Carrinho
   * 
   * 
   */
   deleteItem(id){

    for(var index = 0;index < this.produtos.length;index++) {

      if(id == this.produtos[index].id){
        this.produtos.splice(index,1)
      }
        
    }
    this.calcTotal();

    this.storage.setDataCarrinho({
      produtos: this.produtos,
      total: this.total
    }); 
  
  }
  /**
   * 
   * Calcula o Total do Carrinho
   * 
   */
   calcTotal(){

      this.total = 0

      this.produtos.forEach(s => {

        this.total += s.total;

      });
      this.cart.emitirUpdateTotalCart.emit({
        total: this.total,
        qtd: this.produtos.length
      });

  }
  /**
   * 
   * Busca os dados do Carrinho
   * 
   */
   getData(){

    try{

      this.loader = true

      this.api.loja().carrinho().get().subscribe(response =>{
        
        this.loader   = false

        if(response.status == 1){  
          
          this.response = response

          if(this.response.data == null){
            
            this.cart.emitirUpdateTotalCart.emit({
              total: 0,
              qtd: 0
            });

          }else{

            this.produtos = response.data.produtos;
            this.total    = response.data.total;
            this.cart.emitirUpdateTotalCart.emit({
              total: this.total,
              qtd: this.produtos.length
            });   
            
            this.storage.setDataCarrinho({
              produtos: this.produtos,
              total: this.total,
              qtd: this.produtos.length
            });
          
          }

        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }

        this.app.setMetaDados("Carrinho de Compras");
        this.app.toTop();
        
      
      },(err) =>{
        
        this.loader = false
        this.response = {
          data: err._body,
          status: 500
        }

        this.app.toTop();

      })

    }catch(e){

      this.loader = false
      this.response = {
        data: "Houve um erro Inesperado: "+e.message,
        status: 500
      }

    }

  }
  /**
   * 
   * Set Data Carrinho
   * 
   */
  setDataCarrinho(){

    let data = this.storage.getDataCarrinho();

    if(data != null){

      this.produtos = data.produtos;
      this.total    = data.total;
  
    }


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onData();
    this.setDataCarrinho();
  }

}
