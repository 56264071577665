<div class="modulo modulo-rede-social">
  <div [ngSwitch]="template">
    <div *ngSwitchCase="null" class="default">
      <div class="modulo-content modulo-content-footer">
        <span class="title">Nossas Redes Sociais</span>
        <div class="redes-sociais">
          <div class="box">
              <a [href]="data.params.facebook" target="_blank">
                <div class="icon icon-rede-social icon-facebook">
                  <i class="fa-brands fa-facebook-f"></i>
                </div>  
              </a>    
            </div> 
          <div class="box"> 
            <a [href]="data.params.instagram" target="_blank">
              <div class="icon icon-rede-social icon-instagram">
                <i class="fa-brands fa-instagram"></i>  
              </div>  
            </a>    
          </div>
          <div class="box" *ngIf="data.params.tiktok != '' && data.params.tiktok != null">
            <a [href]="data.params.tiktok" target="_blank">
              <div class="icon icon-rede-social icon-tiktok">
                <i class="fa-brands fa-tiktok"></i>    
              </div>
            </a>    
          </div> 
          <div class="box" *ngIf="data.params.youtube != '' && data.params.youtube != null">
            <a [href]="data.params.youtube" target="_blank">
              <div class="icon icon-rede-social icon-youtube">
                <i class="fa-brands fa-youtube"></i> 
              </div>
            </a>    
          </div> 
        </div>
      </div>  
    </div>
    <div *ngSwitchCase="'page-contato'" class="page-contato">
      <div class="modulo-content modulo-content-footer">
        <div class="contact-item contact-item-social">
          <span class="title">Nos siga nas redes sociais</span>
          <div class="redes-sociais-content">
            <div class="box">
                <a [href]="data.params.facebook" target="_blank">
                  <img src="images/icons/redes-sociais/facebook-blue.svg" alt="Facebook Lapidace" width="40px" />
                </a>    
              </div> 
            <div class="box"> 
              <a [href]="data.params.instagram" target="_blank">
                <img src="images/icons/redes-sociais/instagram-blue.svg" alt="Instagram Lapidace" width="40px" />
              </a>    
            </div>
            <div class="box" *ngIf="data.params.tiktok != '' && data.params.tiktok != null">
              <a [href]="data.params.tiktok" target="_blank">
                <img src="images/icons/redes-sociais/tiktok.svg" alt="Tiktok Lapidace" width="40px" />
              </a>    
            </div> 
            <div class="box" *ngIf="data.params.youtube != '' && data.params.youtube != null">
              <a [href]="data.params.youtube" target="_blank">
                <img src="images/icons/redes-sociais/youtube.svg" alt="Youtube Lapidace" width="40px" /> 
              </a>    
            </div> 
          </div>
        </div>
      </div>  
    </div>
  </div>  
</div> 
