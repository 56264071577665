<div class="module module-products" (window:resize)="setWidth($event)">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2>{{data.titulo}}</h2>
              <h3>{{data.subtitulo}}</h3>
          </div>    
      </div>
      <div class="module-content">
          <div class="container">
            <produto-item-slider-two [produtos]="data.produtos"></produto-item-slider-two>
          </div>
      </div>
      <div class="see-all center">
          <div class="content">
                <a class="btn-custom-1" routerLink="/produtos" title="Produtos" (click)="toRoute('/produtos')">
                    <span>Ver todos</span>
                    <div class="icon">
                        <i class="fa-solid fa-arrow-right"></i>
                    </div>
                </a> 
          </div>
      </div>  
  </div>    
  