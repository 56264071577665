<div class="produto-slider-item">
      <div *ngIf="type == 1" class="products slider-items" #slider> 
            <div class="slider-item" *ngFor="let p of produtos"> 
                  <produto-item [data]="p"></produto-item>
            </div>
      </div>
      <div *ngIf="type == 2" class="products slider-items" #slider> 
            <div class="slider-item" *ngFor="let p of produtos">   
                  <produto-categoria-item [data]="p"></produto-categoria-item>
            </div>
      </div>
      <div class="swiper-button-prev sw-btn" (click)="prev()">
            <i class="fa-solid fa-angle-left"></i>
      </div>
      <div class="swiper-button-next sw-btn" (click)="next()">
            <i class="fa-solid fa-angle-right"></i>
      </div> 
</div>