import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'produto-intencao-modal',
  templateUrl: './produto-intencao-modal.component.html',
  styleUrls: ['./produto-intencao-modal.component.scss']
})
export class ProdutoIntencaoModalComponent implements OnInit {

  public dataItem:any = {
    nome: ""
  };
  public loader = false;

  constructor(
    public dialog: MatDialogRef<ProdutoIntencaoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private api: ApiService,
    private app: AppService,
    private validator: ValidatorService
  ) { }

  /** 
   * 
   * Send 
   * 
   **/
  send(){

    try{

      this.validator.clear();

      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe seu Nome.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe seu Telefone.");

      if(this.dataItem.email !== "" && this.dataItem.email !== null){

        this.validator.isEmail(this.dataItem.email,"email","- O e-mail informado é inválido.");

      } 

      if(!this.validator.passes()){

        let error = this.validator.getMessagesHtml();

        this.app.info(error); 
        return false;

      }

      this.loader = true;
      
      this.api.produto().registrarIntencao(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){   

          this.app.info("Recebemos o seu contato. Quando tivermos a peça em estoque entraremos em contato com você.");
          this.initDataItem();
          this.dialog.close();  

        }else{

          this.app.infoError(response.data);

        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);

        if(error.status == 422){

          this.app.info(error.message);

        }else{

          this.app.infoError(error.message);

        }

      });

    }catch(e){

      this.app.infoError(e);
      this.loader = true;

    }

  }
  /**
   * 
   * Init Data Item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      telefone: "",
      email: "",  
      produto_id: this.data.produtoId,
      descricao: ""
    }


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
