import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageErrorComponent } from 'src/app/components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from 'src/app/components/breadcrumbs/breadcrumbs.component';
import { PartialCompraSeguraComponent } from 'src/app/components/partial/partial-compra-segura/partial-compra-segura.component';
import { PartialPageTitleComponent } from 'src/app/components/partial/partial-page-title/partial-page-title.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { InputCheckComponent } from 'src/app/components/input/input-check/input-check.component';
import { MenuComponent } from 'src/app/components/partial/menu/menu.component';
import { HeaderComponent } from 'src/app/components/partial/header/header.component';
import { FooterComponent } from 'src/app/components/partial/footer/footer.component';
import { BtnCartModule } from '../carrinho/btn-cart/btn-cart.module';
import { ModuloModule } from '../modulo/modulo.module';
import { MenuAboutComponent } from 'src/app/components/partial/menu-about/menu-about.component';
import { CopyrightComponent } from 'src/app/components/partial/copyright/copyright.component';
import { MenuAttendanceComponent } from 'src/app/components/partial/menu-attendance/menu-attendance.component';
import { MenuServicesComponent } from 'src/app/components/partial/menu-services/menu-services.component';
import { BtnUsuarioLoginModule } from '../btn/btn-usuario-login/btn-usuario-login.module';
import { ToUpComponent } from 'src/app/components/partial/to-up/to-up.component';
import { MenuMobileComponent } from 'src/app/components/partial/menu-mobile/menu-mobile.component';

@NgModule({
  declarations: [
    PageErrorComponent,
    BreadcrumbsComponent, 
    PartialCompraSeguraComponent,
    PartialPageTitleComponent,
    InputCheckComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MenuAboutComponent,
    CopyrightComponent,
    MenuAttendanceComponent,
    MenuServicesComponent,
    PartialCompraSeguraComponent,
    ToUpComponent,
    MenuMobileComponent
  ],
  exports: [ 
    PageErrorComponent,
    BreadcrumbsComponent,
    PartialCompraSeguraComponent,
    PartialPageTitleComponent,
    InputCheckComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    MenuAboutComponent,
    CopyrightComponent,
    MenuAttendanceComponent,
    MenuServicesComponent,
    PartialCompraSeguraComponent,
    ToUpComponent,
    MenuMobileComponent
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule,
    BtnCartModule,
    BtnUsuarioLoginModule,  
    ModuloModule  
  ]
})
export class PartialModule{}
