import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'dm-card-estoque-modal',
  templateUrl: './dm-card-estoque-modal.component.html',
  styleUrls: ['./dm-card-estoque-modal.component.scss']
})
export class DmCardEstoqueModalComponent implements OnInit {

  public nome  = null;
  public data  = null;

  constructor(
    public dialog: MatDialogRef<DmCardEstoqueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private router: Router
  ){

    this.data = this._data.data;
    this.nome = this._data.nome;

  }
  /**
   * 
   * Close
   * 
   **/
  _close(){

    this.dialog.close();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
