import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContatoComponent } from 'src/app/modulos/contato/contato.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [
    ContatoComponent
  ],
  exports: [
    ContatoComponent
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule
  ]
})
export class ModuloContatoModule { }
