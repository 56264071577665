import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappComponent } from 'src/app/modulos/whatsapp/whatsapp.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoaderModule } from '../../loader/loader/loader.module';

@NgModule({
    declarations: [
        WhatsappComponent
    ],
    exports: [
        WhatsappComponent
    ],
    imports: [
        CommonModule,
        LazyLoadImageModule,
        LoaderModule
    ]
})
export class ModuloWhatsappModule { }
