<div class="modulo modulo-atendimento-personalizado" [ngStyle]="{'background-image': 'url(images/atendimento/bg.jpg)'}">
      <div class="modulo-content">
            <div class="container">
                  <div class="row">
                        <div class="col-md-6">
                              <h2>{{data.titulo}}</h2>
                              <article class="descricao" [innerHtml]="data.params.texto"></article>
                        </div>
                        <div class="col-md-6">
                              <div class="content-form">
                                    <form class="form form-custom" (submit)="send()">
                                          <div class="form-group">
                                                <label>Seu nome completo:</label>
                                                <input type="text" [(ngModel)]="dataItem.nome" name="nome" uppercase />
                                          </div>
                                          <div class="form-group">
                                                <label>Seu WhatsApp:</label>
                                                <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" mask="(99) 99999-9999" />
                                          </div>
                                          <div class="form-group">
                                                <label>Seu E-mail (Opcional):</label>
                                                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="email@host.com.br" />
                                          </div>
                                          <div class="form-group">
                                                <label>Descrição (Opcional):</label>
                                                <textarea [(ngModel)]="dataItem.texto" name="texto" placeholder="Descreva brevemente o que você procura." rows="7"></textarea>
                                          </div>
                                          <div class="form-group form-button">
                                                <button type="submit" class="btn-one">
                                                      <span>Enviar</span>
                                                </button>
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      <div class="bg"></div> 
</div> 
<loader *ngIf="loader"></loader>   
  