<div class="dm-cart-sidebar" [ngClass]="{'show slide-left':open,'slide-right':!open}">
      <div class="header">
            <div class="icon" (click)="_close()">
                  <i class="fa-solid fa-angle-right"></i> 
            </div>
            <span>Minhas Compras</span>
      </div>
      <div class="content">
            <div class="item" *ngFor="let p of produtos"> 
                  <dm-cart-item-two [data]="p"></dm-cart-item-two>
            </div>  
            <div class="empty" *ngIf="produtos.length == 0">
                  <p>Você ainda não possui itens no seu carrinho de compras.</p>
            </div>
            <loader classCustom="absolute" *ngIf="loader"></loader>
      </div>
      <div class="footer">
            <div class="subtotal">
                  <div class="content">
                        <span>Subtotal:</span>
                        <strong>{{total | currency: " R$ "}}</strong>
                  </div>
            </div>
            <div class="content-button">
                  <div class="box"> 
                        <button type="button" class="btn-buy" (click)="_close()">
                              <span>Continuar Comprando</span>
                        </button>
                  </div>
                  <div class="box">
                        <button type="button" class="btn-payment" (click)="_toCart()">
                              <span>Finalizar Compra</span>
                        </button>
                  </div>
            </div>
      </div>
</div> 
