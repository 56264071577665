import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{ 
  path: '',     
  //loadChildren: () => HomeModule  
  loadChildren: () => import('src/app/modules/home/home.module').then(m => m.HomeModule) 
},{
  path: 'servicos/cuidados-com-suas-joias',   
  loadChildren: () => import('src/app/modules/pages/servicos/cuidados-page/cuidados-page.module').then(m => m.CuidadosPageModule) 
},{ 
  path: 'quem-somos',   
  loadChildren: () => import('src/app/modules/sobre/sobre-module/sobre.module').then(m => m.SobreModule) 
},{
  path: 'perguntas-frequentes',   
  loadChildren: () => import('src/app/modules/pages/perguntas-frequentes-page/perguntas-frequentes-page.module').then(m => m.PerguntasFrequentesPageModule) 
},{
  path: 'formas-de-pagamento',   
  loadChildren: () => import('src/app/modules/pages/formas-pagamento-page/formas-pagamento-page.module').then(m => m.FormasPagamentoPageModule)  
},{
  path: 'carrinho',  
  loadChildren: () => import('src/app/modules/checkout/checkout-carrinho/checkout-carrinho.module').then(m => m.CheckoutCarrinhoModule) 
},{
  path: 'dicas',  
  loadChildren: () => import('src/app/modules/pages/dica/dica-page/dica-page.module').then(m => m.DicaPageModule) 
},{
  path: 'carrinho/pedido',     
  loadChildren: () => import('src/app/modules/pages/checkout/checkout-pedido-page/checkout-pedido-page.module').then(m => m.CheckoutPedidoPageModule)  
},{
  path: 'carrinho/pedido/finalizado',     
  loadChildren: () => import('src/app/modules/pages/checkout/checkout-finalizado-page/checkout-finalizado-page.module').then(m => m.CheckoutFinalizadoPageModule)  
},{ 
  path: 'produtos/tipo/:apelido_tipo',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/colecao/:apelido_colecao',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{
  path: 'produtos',
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{
  path: 'contato',   
  loadChildren: () => import('src/app/modules/contato/contato.module').then(m => m.ContatoModule) 
},{
  path: 'politicas-de-privacidade',
  loadChildren: () => import('src/app/modules/politica/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
},{
  path: 'politicas-de-cancelamento', 
  loadChildren: () => import('src/app/modules/politica/politica-cancelamento/politica-cancelamento.module').then(m => m.PoliticaCancelamentoModule)
},{
  path: 'politicas-de-cookies', 
  loadChildren: () => import('src/app/modules/politica/politica-cookie/politica-cookie.module').then(m => m.PoliticaCookieModule)
},{
  path: 'politicas-de-troca',
  loadChildren: () => import('src/app/modules/pages/politicas-de-troca-page/politicas-de-troca-page.module').then(m => m.PoliticasDeTrocaPageModule)
},{
  path: 'politicas-de-conserto',
  loadChildren: () => import('src/app/modules/pages/politicas-de-conserto-page/politicas-de-conserto-page.module').then(m => m.PoliticasDeConsertoPageModule)
},{
  path: 'politicas-de-garantia', 
  loadChildren: () => import('src/app/modules/pages/politicas-de-garantia-page/politicas-de-garantia-page.module').then(m => m.PoliticasDeGarantiaPageModule)
},{
  path: 'depoimento/sua-avaliacao', 
  loadChildren: () => import('src/app/modules/pages/depoimento/depoimento-page/depoimento-page.module').then(m => m.DepoimentoPageModule)
},{
  path: 'depoimento/sua-avaliacao/enviada', 
  loadChildren: () => import('src/app/modules/pages/depoimento/depoimento-sucesso-page/depoimento-sucesso-page.module').then(m => m.DepoimentoSucessoPageModule)
},{ 
  path: 'sorteio/:apelido',    
  loadChildren: () => import('src/app/modules/pages/sorteio/sorteio-page/sorteio-page.module').then(m => m.SorteioPageModule) 
},{ 
  path: 'sorteio/:apelido',    
  loadChildren: () => import('src/app/modules/pages/sorteio/sorteio-page/sorteio-page.module').then(m => m.SorteioPageModule) 
},{ 
  path: 'para-revender',    
  loadChildren: () => import('src/app/modules/pages/atacado/revenda-page/revenda-page.module').then(m => m.RevendaPageModule) 
},{ 
  path: 'minha-conta/perfil',    
  loadChildren: () => import('src/app/modules/pages/minha-conta/meu-perfil-page/meu-perfil-page.module').then(m => m.MeuPerfilPageModule) 
},{ 
  path: 'minha-conta/pedidos',    
  loadChildren: () => import('src/app/modules/pages/minha-conta/meu-pedido-page/meu-pedido-page.module').then(m => m.MeuPedidoPageModule) 
},{   
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
