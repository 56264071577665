import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'modulo-atendimento-personalizado',
  templateUrl: './atendimento-personalizado.component.html',
  styleUrls: ['./atendimento-personalizado.component.scss']
})
export class AtendimentoPersonalizadoComponent implements OnInit {

  @Input("template") template         = "";
  @Input("data") data:any             = {};
  @Input("classCustom") classCustom   = ""; 
  @ViewChild("slider") slider:ElementRef;  
  public dataItem:any = {};
  public loader = false;
  
  constructor(
    private api: ApiService,
    private app: AppService,
    private validator: ValidatorService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){}  

  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome", "- Informe o Nome.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone", "- Informe o Telefone.");

      if(this.dataItem.email != ""){
        this.validator.isEmail(this.dataItem.email,"email", "- Informe um E-mail válido.");
      }

      if(!this.validator.passes()){

        let error = this.validator.getMessagesHtml();

        this.app.info(error);
        return false;

      }

      this.loader = true;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token  = token;

          this.api.contato().send(this.dataItem).subscribe(response => {

            this.loader = false;

            if(response.status == 1){ 

              this.app.info("Recebemos o seu contato. Entraremos em contato em breve com você.");
              this.initDataItem();

            }else{

              this.app.infoError(response.data);

            }

          },(response) => {

            this.loader = false;
            let error   = this.api.formatError(response);

            if(response.status == 422){
              this.app.info(response.data);
            }else{
              this.app.infoError(error.message);
            } 

          });

      },(response) => {

          this.loader         = false; 
          let error           = this.api.formatError(response);
          let messages        = error.message; 
          this.app.infoError(messages); 

      });
      

    }catch(e){

      this.loader = false;
      this.app.infoError(e.message);

    }

  }
  /**
   * 
   * Set data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      apelido: "atendimento-personalizado",
      tipo: 3,
      texto: ""
    }

  }
  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{
    this.initDataItem(); 
  }

}
