import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'produto-categoria-item',
  templateUrl: './produto-categoria-item.component.html',
  styleUrls: ['./produto-categoria-item.component.scss']
})
export class ProdutoCategoriaItemComponent implements OnInit {

  @Input("data") data:any = null;
  public link = null;

  constructor(
    private router: Router
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(url){
 
    this.router.navigateByUrl(url);

  }
  /**
   * 
   * Set link
   * 
   */
  setLink(){

    this.link = "/produtos/"+this.data.apelido;

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setLink();
  }

}
