import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class ModuloLogo implements OnInit {

  @Input("data") data;
  public logo = null;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null; 

  constructor(private router: Router){
    
  }  

  /**
   * 
   * Router
   * 
   */
  _click(){

    this.router.navigateByUrl("/");

  }
  /***
   * 
   * Logo
   * 
   */
  setLogo(){ 

    this.logo = "/images/modulo/logo/"+this.data.params.imagem; 
 
  } 
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLogo();
  }

}
