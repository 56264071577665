import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'modulo-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("template") template       = null;
  @Input("classCustom") classCustom = null;
  @ViewChild("video") video:any; 
  @Input("muted") muted:any = true; 
  
  constructor(){}

  /**
   * 
   * On Lazy
   * 
   */
  onLazy(){

    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if("IntersectionObserver" in window) { 
    
      var lazyVideoObserver = new IntersectionObserver(function(entries,observer) {

        entries.forEach(function(video:any) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource:any = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.title;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * After load
   * 
   */
   ngAfterViewInit(): void {
          
    setTimeout(() => {
      
      this.onLazy();

      var autoPlayVideo:any = this.video.nativeElement;
                
      autoPlayVideo.muted = this.muted;
      autoPlayVideo.play();

    },50);
    

  }

}
