import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'dm-cart-item-two',
  templateUrl: './dm-cart-item-two.component.html',
  styleUrls: ['./dm-cart-item-two.component.scss']
})
export class DmCartItemTwoComponent implements OnInit {

  @Input("data") item:any     = null; 
  @Input("configuracaoLoja") configuracaoLoja = null;
  @Input("index") index   = null;
  public showPassageiros  = false;
  public link = null;

  constructor(
    private dialog: MatDialog,    
    private cart: CartService,
    private router: Router
  ){ 
  }
  /**
   * 
   * Route
   * 
   */
  _route(){

    this.router.navigateByUrl(this.link); 

  }
  /***
   * 
   * On data
   * 
   */
  onData(){

    /*** emitir add item */
    this.cart.emitirAddItem.subscribe(item => {

      if(item.produto_id == this.item.produto_id){
        this.item.valor      = item.valor;
        this.item.quantidade = item.quantidade;
        this.item.total      = item.total;  
      }

    });
    

  }
  /**
   * 
   * Set link
   * 
   */
  setLink(){

    this.link = "/produtos/"+this.item.apelido_categoria+"/"+this.item.apelido;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.onData();
    this.setLink();
  }

}
