<div class="modulo modulo-grupo-whatsapp">
      <div class="modulo-content">
            <div class="box">
                  <div class="box-title">
                      <h3>{{data.titulo}}</h3>
                      <h4 [innerHtml]="data.params.texto"></h4>
                  </div> 
                  <div class="box-content box-center">  
                      <a class="link-whatsapp-group" [href]="data.params.link" title="WhatsApp Lapidace" target="_blank">
                          <i class="material-icons">whatsapp</i>
                          <span>Entrar no Grupo</span>
                      </a>
                  </div>
            </div>            
      </div>
</div>