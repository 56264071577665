import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any; 

@Component({
  selector: 'modulo-produto-destaques',
  templateUrl: './produto-destaques.component.html',
  styleUrls: ['./produto-destaques.component.scss']
})
export class ProdutoDestaquesComponent implements OnInit {

  @Input("data") data;
  @Input("classCustom") classCustom = "";
  @Input("template") template       = null;  
  public produtos                   = []; 

  @ViewChild("slider") slider: any;
  public products:any = [];
  public tabSelect = 0;
  public tabs:any = [];
  public width = 0;

  constructor(
    private router: Router
  ) { }

  /**
   * 
   * Rouer
   * 
   ***/
  toRoute(){

    let url = "/produtos/"+this.data.categoria.apelido;

    this.router.navigateByUrl(url); 

  }
  /**
   * 
   * Select tab
   * 
   * @param y 
   * 
   */
  selectTab(y:any){

    this.tabSelect = y;

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    if(typeof(this.slider) != "undefined"){

      $(this.slider.nativeElement).slick({ 
        infinite: true,
        slidesToShow: 4,
        dots: false, 
        arrows: false,
        centerMode: false,
        centerPadding: '0',
        responsive: [{
            breakpoint: 1224,
            settings: {
              slidesToShow: 4,  
            }
        },{
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,

            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              centerPadding: '0',
            }
        }
      ]
      
      }); 
      $(this.slider.nativeElement).slick('refresh'); 
    
    }
    
  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }

  /**
   * 
   * Set width
   * 
   */
  setWidth(value:any){

    this.width = value.target.innerWidth;
    setTimeout(() => {
      this.initSlider();
    },100);
    
  }
  /** 
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.width = window.innerWidth;
  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }

}
