import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoIntencaoModalComponent } from 'src/app/components/produto/produto-intencao-modal/produto-intencao-modal.component';

@Component({
  selector: 'btn-cart-sem-estoque',
  templateUrl: './btn-cart-sem-estoque.component.html',
  styleUrls: ['./btn-cart-sem-estoque.component.scss']
})
export class BtnCartSemEstoqueComponent implements OnInit {

  @Input("produtoId") produtoId = null;

  constructor(
    public dialog: MatDialog  
  ){}

  /**
   * 
   * Open dialog
   * 
   */
  _open(){   

    this.dialog.open(
      ProdutoIntencaoModalComponent,{
        width: "600px",
        data: {
          produtoId: this.produtoId
        }
      }
    ); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
