<div class="product-category-item">
    <a href="javascript::void();" (click)="toRoute(link)" title="{{data.titulo}}">
          <div class="photo">
              <img [src]="data.capa" alt="Capa" width="265px" height="320px" />
          </div>
          <div class="content">
              <h3>{{data.titulo}}</h3>
          </div>
          <div class="bg"></div> 
    </a>
</div>  
   
  
