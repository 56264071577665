<div class="dm-modal">
    <div class="dm-modal-title">
        <span>Seu contato</span>
        <div class="icon" (click)="dialog.close()">
            <i class="far fa-times-circle"></i>
        </div>     
    </div> 
    <div class="dm-modal-content">
            <form class="form-custom" (submit)="send()"> 
                <article class="description">
                    <p>Seu contato é importante para atendermos melhor <strong>você.</strong></p>
                </article>    
                <div class="form-group" *ngIf="configuracao.solicitar_email_pre_venda">
                    <label>E-mail:</label>
                    <input type="text" [(ngModel)]="dataItem.email" name="email" />
                </div> 
                <div class="form-group" *ngIf="configuracao.solicitar_telefone_pre_venda">
                    <label>Telefone (WhatsApp):</label>
                    <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" mask="(99) 99999-9999" placeholder="(99) 99999-9999" />
                </div>   
              <div class="form-group">
                  <button type="submit" class="btn-one">
                    <i class="fa-solid fa-cart-shopping"></i>
                    <span>Adicionar ao Carrinho</span>
                  </button>    
              </div>
          </form>    
    </div>  
  </div>    