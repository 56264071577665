import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GtagService } from 'src/app/services/gtag.service';

declare var $:any;

@Component({
  selector: 'modulo-depoimentos',
  templateUrl: './depoimentos.component.html',
  styleUrls: ['./depoimentos.component.scss']
})
export class DepoimentosComponent implements OnInit {

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  @ViewChild("slider") slider:ElementRef;
  
  constructor(
    private gtag: GtagService 
  ) { }
      

  /*prev(){ 

    this.slider.nativeElement.scrollLeft -= 375;

  }
  next(){


    this.slider.nativeElement.scrollLeft += 375;


   }*/
   /***
   * 
   * Inicializa o Slider
   * 
   */
    initSlider(){

      $(this.slider.nativeElement).slick({ 
        infinite: true,
        slidesToShow: 3,
        dots: false, 
        arrows: false,
        centerMode: true,
        autoplay: true,
        centerPadding: '20px',
        responsive: [{
            breakpoint: 1224,
            settings: {
              slidesToShow: 3,  
            }
        },{
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
  
            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              centerPadding: '0',
            }
        }
      ]
      
      }); 
      $(this.slider.nativeElement).slick('refresh'); 
      
  
    }
    /**
     * 
     * Next
     * 
     */
    prev(){ 
  
      $(this.slider.nativeElement).slick("slickPrev");  
  
    }
    /**
     * 
     * Next
     * 
     */
    next(){
  
      $(this.slider.nativeElement).slick("slickNext"); 
  
    }
    /**
     * 
     * On init
     * 
     */
    ngOnInit(): void {
      
    }
    /**
     * 
     * Incializa as Funções
     * 
     */
    ngAfterViewInit(): void {
     this.initSlider();
    }


}
