import { AfterViewInit, Component,Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'produto-item-slider-two',
  templateUrl: './produto-item-slider-two.component.html',
  styleUrls: ['./produto-item-slider-two.component.scss']
})
export class ProdutoItemSliderTwoComponent implements OnInit, AfterViewInit{

  @Input("produtos") produtos = [];
  @ViewChild("slider") slider:any; 
  @Input("type") type = 1; 
  
  constructor(){} 
  

  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Inicializa o banner
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: false,
      autoplaySpeed: 2500,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 1,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 

    $(this.slider.nativeElement).slick('refresh'); 

  }
  /***
   * 
   * Init
   * 
   ***/
  ngOnInit():void{}
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
