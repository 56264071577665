import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtendimentoPersonalizadoComponent } from 'src/app/modulos/atendimento-personalizado/atendimento-personalizado.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../loader/loader/loader.module';
import { DirectivesModule } from '../../directives/directives/directives.module';


@NgModule({
  declarations: [
    AtendimentoPersonalizadoComponent
  ],
  exports: [
    AtendimentoPersonalizadoComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    DirectivesModule
  ]
})
export class ModuloAtendimentoPersonalizadoModule{}
