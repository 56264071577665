import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-produto-categorias',
  templateUrl: './produto-categorias.component.html',
  styleUrls: ['./produto-categorias.component.scss']
})
export class ProdutoCategoriasComponent implements OnInit {

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  
  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
