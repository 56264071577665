<div class="module module-newsletter">
  <div class="module-content">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="box">
                    <h3>{{data.titulo}}</h3>
                    <h4>{{data.subtitulo}}</h4>
                </div>
            </div>
            <div class="col-md-8">
                <div class="box">
                    <form class="form form-custom" (submit)="send()"> 
                        <div class="form-group">
                            <input type="text" [(ngModel)]="dataItem.nome" placeholder="Seu nome*" name="nome" />
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="dataItem.email" placeholder="Seu e-mail*" name="email" />
                        </div>
                        <div class="form-group">
                            <input type="text" mask="(99) 99999-9999" [(ngModel)]="dataItem.telefone" placeholder="Seu WhatsApp*" name="telefone" />
                        </div> 
                        <div class="form-group form-button">
                            <button class="btn-custom-1"> 
                                <span>enviar</span>
                            </button>    
                        </div>   
                    </form>
                </div>
            </div>
        </div>
    </div>   
  </div>
</div> 
<loader [hidden]="!loader"></loader>
