import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnUsuarioLoginComponent } from 'src/app/components/btns/btn-usuario-login/btn-usuario-login.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    BtnUsuarioLoginComponent
  ],
  exports: [
    BtnUsuarioLoginComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule
  ],
  providers: [
  ]
})
export class BtnUsuarioLoginModule { }
