<div *ngIf="template == null || template == 'null'" class="module-whatsapp" (click)="open()">
  <div class="content">
    <div class="icon-whatsapp">
      <i class="fa-brands fa-whatsapp"></i>
    </div> 
    <div class="text">
      <span>Fale com uma <br>consultora.</span>
    </div>  
  </div>   
</div>    
<div *ngIf="template == 'page-contato'" class="module-whatsapp-contato" (click)="open()">
  <div class="contact-item"> 
    <span class="title">WhatsApp</span> 
    <strong class="value">{{data.params.telefone}}</strong>
  </div> 
</div>   
<div *ngIf="template == 'checkout-pedido'" class="module-whatsapp-pedido" (click)="open()">
  <div class="content-item">  
    <span class="title">Clique aqui e fale por WhatsApp</span> 
    <strong class="value"> 
      <i class="fa-brands fa-whatsapp"></i>
      {{data.params.telefone}}
    </strong>
  </div> 
</div>      
