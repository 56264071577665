import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'dm-cart-contato',
  templateUrl: './dm-cart-contato.component.html',
  styleUrls: ['./dm-cart-contato.component.scss']
})
export class DmCartContatoComponent implements OnInit {

  public configuracao = null;
  public dataItem = {
    telefone: null,
    email: null
  }

  constructor(
    public dialog: MatDialogRef<DmCartContatoComponent>,   
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    private validator: ValidatorService,
    private app: AppService
  ){}  

  /**
   * 
   * sending..
   * 
   */
  send(){

    try{
      this.validator.clear();
      
      if(this.configuracao.solicitar_email_pre_venda){
        this.validator.isEmpty(this.dataItem.email,"email","- Informe o seu E-mail.");
        this.validator.isEmail(this.dataItem.email,"email","- O E-mail que você informou é inválido.");
      }
      if(this.configuracao.solicitar_telefone_pre_venda){
        this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe o seu Telefone (WhatsApp).");
      }

      if(!this.validator.passes()){

        let error = this.validator.getMessagesHtml();
        this.app.info(error);
        return false;

      }

      this.data.dataItem.telefone = this.dataItem.telefone;
      this.data.dataItem.email    = this.dataItem.email;

      this.dataService.setContatoCart({
        email: this.dataItem.email,  
        telefone: this.dataItem.telefone
      });  

      this.dialog.close();
      this.data.self.add(false);

      return false; 
      
    }catch(e){
      

    }

  }
  /**
   * 
   * Set configuracao
   * 
   */
  setConfiguracao(){

    this.configuracao = this.dataService.getConfiguracao();  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setConfiguracao();
  }

}
